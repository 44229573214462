<nav class="navbar box-shadow app-header" [class.fixed-top]="settings.theme.navbarIsFixed" [class.logo-visible]="showInfoContent">
  <div class="d-flex flex-column w-100">
    <div id="info-content" class="panel-collapse collapse">
      <a class="close" data-toggle="collapse" href="#info-content" (click)="showInfoContent = !showInfoContent" [class.show]="showInfoContent">
        <span aria-hidden="true">&times;</span>
      </a>
      <div class="row align-items-center">
        <div class="col-md-5">

        </div>
        <div class="col-md-7">
          <div class="row py-4">
            <div class="col-lg-7 col-sm-8 col-12 left-border">
              <div class="info w-280p mx-auto text-center">

              </div>
            </div>
            <div class="col-lg-5 col-sm-4 left-border d-none d-sm-block">

            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-start align-items-center top-navbar">

        <a class="logo" href="javascript:void(0)" (click)="closeSubMenus()">
            <!--<span class="ng">B & D</span>-->
            <img src="../../../../assets/img/NewBDMLogo1 (2).jpeg" style="width:100% ; margin-left: 0%;" />
            <!--<img src="assets/img/app/VechicleLogo.PNG" style="width:100px;margin-left: 41px;" />-->
        </a>

      <span class="navbar-text menu-icon transition ml-3 mr-3" [ngClass]="{'open': settings.theme.showMenu}"
            (click)="settings.theme.showMenu = !settings.theme.showMenu">
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
      </span>



      <div class="top-rigth-icons ml-auto">

        <span class="d-none d-sm-inline-block">

        </span>
        <span class="pl-2 pr-2">
          Hi&nbsp;<b>{{Logindet.Name}}</b>&nbsp;<i class="fa fa-sign-out" aria-hidden="true" (click)="logout()"></i>
          <!--Hi&nbsp;<b>Admin</b>&nbsp;<i class="fa fa-sign-out" aria-hidden="true" (click)="logout()"></i>-->
        </span>

      </div>

    </div>



  </div>
</nav>
