import { SalesincentivesComponent } from 'src/app/pages/Admin/salesincentives/salesincentives.component';
import { Menu } from './menu.model';

export const verticalMenuItems = [

    new Menu(1, 'Dashboard', '/Dashboard', null, 'tachometer', null, false, 0),
    //new Menu(11, 'Application Form', '/Admin/applicationform', null, 'file-o', null, false, 0),
    new Menu(2, 'Customer list', '/Admin/Userlist', null, 'th-large', null, false, 0),
    new Menu(3, 'Employee list', '/Admin/Employees', null, 'users', null, false, 0),
    new Menu(4,'Reports', null, null, 'laptop', null, true, 0),
    new Menu(5, 'Attendance Report', '/Admin/AttendenceReport', null, 'expand', null, false, 4),
    new Menu(6, 'Masters', null, null, 'list-ul', null, true, 0),
    new Menu(24, 'Branch Master', '/Admin/Branchmaster', null, 'users', null, false, 6),
   
    new Menu(8, 'Down Payment', '/Admin/downpayment', null, 'users', null, false, 6),
    new Menu(17, 'Documents', '/Admin/Documents', null, 'users', null, false, 6),
   
    new Menu(10, 'Ventures Master', '/Admin/venturesmaster', null, 'registered', null, false, 6),
    new Menu(14, 'Role Master', '/Admin/role-master', null, 'expand', null, false, 6),
    new Menu(13, 'Send SMS', '/Admin/sms', null, 'bell-o', null, false, 0),
    new Menu(12, 'Birthday-Alert', '/Admin/birthday-alert', null, 'envelope-o', null, false, 0),
    new Menu(15, 'Admin Charges', '/Admin/AdminCharges', null, 'laptop', null, false, 0),
    new Menu(16, 'Guest List', '/Admin/Guestlist', null, 'users', null, false, 0),
    new Menu(18, 'Sales applications', '/Admin/salesapps', null, 'users', null, false, 0),
    new Menu(19, 'Business Report', '/Admin/Businessreport', null, 'book', null, false, 4),
    new Menu(20, 'Incentives', '/Admin/Incentives', null, 'users', null, false, 6),
    //new Menu(20, 'Expansion', '/Admin/Salesapplicationform', null, 'users', null, false, 0),
   // new Menu(16, 'Admin_GuestList', '/Admin/Admin_GuestList', null, 'users', null, false, 0),
    //new Menu(21, 'Incentive Amount', '/Admin/Salesincentives', null, 'money', null, false, 4),
    //new Menu(9, 'Walk-in Incentives', '/Admin/installmentpayment', null, 'users', null, false, 4),
    new Menu(22, 'Payment Master', '/Admin/Paymentmaster', null, 'users', null, false, 6),
    new Menu(23, 'Service Form', '/Admin/Servicefrom', null, 'users', null, false, 0),
    new Menu(7, 'Holiday Packages', '/Admin/holidaypackages', null, 'magic', null, false, 6),
    new Menu(26, 'Coupons Master', '/Admin/Couponsmaster', null, 'magic', null, false, 6),
    new Menu(25, 'Permanentid', '/Admin/Permanentid', null, 'users', null, false, 0),
    //new Menu(26, 'Payment History', '/Admin/PaymentHistory', null, 'history', null, false, 4),
    new Menu(27, 'Coupon Maping', '/Admin/Couponmaping', null, 'history', null, false, 0),
    new Menu(28, 'Incentive Report', '/Admin/Incentivereport', null, 'money', null, false, 4),
    new Menu(29, 'Incentive Payment History', '/Admin/Paymenthistories', null, 'history', null, false, 4),
  //  new Menu(27, 'sales appication test', '/Admin/Salesapplicationform', null, 'users', null, false, 0),
 //   new Menu(23, 'EMI Reminders', '/Admin/reminders', null, 'users', null, false, 0),



]

export const SlaeExecutive = [

    new Menu(1, 'Dashboard', '/Dashboard', null, 'tachometer', null, false, 0),
    new Menu(18, 'Sales applications', '/Admin/salesapps', null, 'users', null, false, 0),
    new Menu(19, 'Business Report', '/Admin/Businessreport', null, 'users', null, false, 0),
    new Menu(28, 'Incentive Report', '/Admin/Incentivereport', null, 'money', null, false, 0),
]

export const SlaeTeamlead = [

         new Menu(1, 'Dashboard', '/Dashboard', null, 'tachometer', null, false, 0),
    //new Menu(11, 'Application Form', '/Admin/Guestlist', null, 'users', null, false, 0),
    new Menu(18, 'Sales applications', '/Admin/salesapps', null, 'users', null, false, 0),
    new Menu(19, 'Business Report', '/Admin/Businessreport', null, 'users', null, false, 0),
    new Menu(28, 'Incentive Report', '/Admin/Incentivereport', null, 'money', null, false, 0),

]

export const Receptionist= [

    new Menu(1, 'Dashboard', '/Dashboard', null, 'tachometer', null, false, 0),
    new Menu(16, 'Guest List', '/Admin/Guestlist', null, 'users', null, false, 0),

]
export const SlaeManager = [

    new Menu(1, 'Dashboard', '/Dashboard', null, 'tachometer', null, false, 0),
    //new Menu(11, 'Application Form', '/Admin/applicationform', null, 'file-o', null, false, 0),
    new Menu(18, 'Sales applications', '/Admin/salesapps', null, 'users', null, false, 0),
    new Menu(19, 'Business Report', '/Admin/Businessreport', null, 'users', null, false, 0),
    new Menu(28, 'Incentive Report', '/Admin/Incentivereport', null, 'money', null, false, 0),
]

export const BDManager = [
    new Menu(17, 'Manager Employees', '/Admin/ManagerEmployees', null, 'users', null, false, 0),
    new Menu(18, 'Manager Customers', '/Admin/ManagerCustomers', null, 'th-large', null, false, 0),
    

]
export const Coordinator = [
    new Menu(1, 'Dashboard', '/Dashboard', null, 'tachometer', null, false, 0),
    new Menu(23, 'Service Form', '/Admin/Servicefrom', null, 'users', null, false, 0),
  

]

export const Customer = [
    new Menu(1, 'Dashboard', '/Dashboard', null, 'tachometer', null, false, 0),
    new Menu(18, 'Sales applications', '/Admin/salesapps', null, 'users', null, false, 0),
   // new Menu(25, 'Permanentid', '/Admin/Permanentid', null, 'users', null, false, 0),
  //  new Menu(23, 'Service Form', '/Admin/Servicefrom', null, 'th-large', null, false, 0),  
    new Menu(28, 'Incentive Report', '/Admin/Incentivereport', null, 'money', null, false, 0),

]


export const horizontalMenuItems = [
    new Menu(1, 'ADMIN_NAV.DASHBOARD', '/', null, 'tachometer', null, false, 0),
    new Menu(2, 'ADMIN_NAV.MEMBERSHIP', '/membership', null, 'users', null, false, 0),
    new Menu(3, 'ADMIN_NAV.UI_FEATURES', null, null, 'laptop', null, true, 0),
    new Menu(4, 'Buttons', '/ui/buttons', null, 'keyboard-o', null, false, 3),
    new Menu(5, 'Cards', '/ui/cards', null, 'address-card-o', null, false, 3),
    new Menu(6, 'Components', '/ui/components', null, 'creative-commons', null, false, 3),
    new Menu(7, 'Icons', '/ui/icons', null, 'font-awesome', null, false, 3),
    new Menu(8, 'List Group', '/ui/list-group', null, 'th-list', null, false, 3),
    new Menu(9, 'Media Objects', '/ui/media-objects', null, 'object-group', null, false, 3),
    new Menu(10, 'Tabs & Accordions', '/ui/tabs-accordions', null, 'server', null, false, 3),
    new Menu(11, 'Typography', '/ui/typography', null, 'font', null, false, 3),
    new Menu(31, 'ADMIN_NAV.TOOLS', null, null, 'wrench', null, true, 3),
    new Menu(32, 'Drag & Drop', '/tools/drag-drop', null, 'hand-paper-o', null, false, 31),
    new Menu(33, 'Resizable', '/tools/resizable', null, 'expand', null, false, 31),
    new Menu(34, 'Toaster', '/tools/toaster', null, 'bell-o', null, false, 31),
    new Menu(20, 'ADMIN_NAV.FORM_ELEMENTS', null, null, 'pencil-square-o', null, true, 0),
    new Menu(21, 'Form Controls', '/form-elements/controls', null, 'check-square-o', null, false, 20),
    new Menu(22, 'Form Layouts', '/form-elements/layouts', null, 'th-large', null, false, 20),
    new Menu(24, 'Form Wizard', '/form-elements/wizard', null, 'magic', null, false, 20),
    new Menu(25, 'Editor', '/form-elements/editor', null, 'pencil', null, false, 20),
    new Menu(26, 'ADMIN_NAV.TABLES', null, null, 'table', null, true, 20),
    new Menu(27, 'Basic Tables', '/tables/basic-tables', null, 'th', null, false, 26),
    new Menu(28, 'Dynamic Tables', null, null, 'th-large', null, true, 26),
    new Menu(30, 'NGX DataTable', '/tables/dynamic-tables/ngx', null, 'caret-right', null, false, 28),
    new Menu(40, 'ADMIN_NAV.PAGES', null, null, 'file-text-o', null, true, 0),
    new Menu(15, 'ADMIN_NAV.DYNAMIC_MENU', '/dynamic-menu', null, 'list-ul', null, false, 40),
    new Menu(43, 'LOGIN', '/login', null, 'sign-in', null, false, 40),
    new Menu(44, 'REGISTER', '/register', null, 'registered', null, false, 40),
    new Menu(45, 'ADMIN_NAV.BLANK', '/blank', null, 'file-o', null, false, 40),
    new Menu(46, 'ADMIN_NAV.ERROR', '/pagenotfound', null, 'exclamation-circle', null, false, 40),
    new Menu(47, 'Profile', null, null, 'user', null, true, 40),
    new Menu(48, 'Projects', '/profile/projects', null, 'file-o', null, false, 47),
    new Menu(49, 'User Info', '/profile/user-info', null, 'address-card-o', null, false, 47),
    new Menu(50, 'ADMIN_NAV.CALENDAR', '/calendar', null, 'calendar', null, false, 40),
    new Menu(16, 'ADMIN_NAV.MAILBOX', '/mailbox', null, 'envelope-o', null, false, 40),
    new Menu(200, 'ADMIN_NAV.EXTERNAL_LINK', null, 'http://themeseason.com', 'external-link', '_blank', false, 40),
    new Menu(66, 'ADMIN_NAV.MAPS', null, null, 'globe', null, true, 0),
    new Menu(67, 'Google Maps', '/maps/googlemaps', null, 'map-marker', null, false, 66),
    new Menu(68, 'Leaflet Maps', '/maps/leafletmaps', null, 'map-o', null, false, 66),
    new Menu(70, 'ADMIN_NAV.CHARTS', null, null, 'area-chart', null, true, 0),
    new Menu(71, 'Bar Charts', '/charts/bar', null, 'bar-chart', null, false, 70),
    new Menu(72, 'Pie Charts', '/charts/pie', null, 'pie-chart', null, false, 70),
    new Menu(73, 'Line Charts', '/charts/line', null, 'line-chart', null, false, 70),
    new Menu(74, 'Bubble Charts', '/charts/bubble', null, 'comment-o', null, false, 70)
]
