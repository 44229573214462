<ol class="breadcrumb" *ngIf="router.url != '/'">  
    <li *ngIf="router.url != '/'" class="breadcrumb-item">
        <a ><i class="fa fa-home mr-2"></i>Home</a>
    </li>
    <li *ngFor="let breadcrumb of breadcrumbs; let i = index;" class="breadcrumb-item">
        <a [hidden]="i == (breadcrumbs.length - 1)" [routerLink]="[breadcrumb.url]">{{breadcrumb.name}}</a>          
        <span [hidden]="i != (breadcrumbs.length - 1)"><i *ngIf="router.url == '/'" class="fa fa-home mr-2"></i><b>{{breadcrumb.name}}</b></span>
    </li>
</ol>
<style>
    .breadcrumb {
        margin-top: -2.5%;
        margin-left: -25px;
        margin-right: -25px;
    }
</style>